<template>
  <div id="app">
    <h2>Api Fns</h2>
    <form class="main-form" @submit.prevent="submit" method="post">
      <div class="main-form-row">
        <span>Метод</span>
        <input placeholder="Метод" v-model="method" type="text" name="" value="">
      </div>
      <div class="main-form-row">
        <span>q*</span>
        <input placeholder="Введите параметр q (Поисковая строка)" v-model="qValue" type="text" name="" value="">
      </div>
      <div class="main-form-row">
        <span>key *</span>
        <input placeholder="Введите параметр key (Ваш ключ API)" v-model="key" type="text" name="" value="">
      </div>
      <button :disabled="disableFns || !qValue" type="submit" name="button">{{disableFns ? "Загрузка..." : "Загрузить"}}</button>
    </form>
    <h2>Api Damia</h2>
    <form class="main-form" @submit.prevent="submitFile" method="post">
      <div class="main-form-row">
        <span>Метод</span>
        <input placeholder="Метод" v-model="daminaUrl" type="text" name="" value="">
      </div>
      <div class="main-form-row">
        <span>Файл</span>
        <input @change="fileChanged" type="file" name="" value="">
      </div>
      <div class="main-form-row">
        <span>key *</span>
        <input placeholder="Введите параметр key (Ваш ключ API)" v-model="multibalanceKey" type="text" name="" value="">
      </div>
      <button :disabled="disableMultibalance || !this.file" type="submit" name="button">{{disableMultibalance ? "Загрузка..." : "Загрузить"}}</button>
    </form>
  </div>
</template>

<script>
import * as xlsx from 'json-as-xlsx';
import XLSX from "xlsx"
export default {
  name: 'App',
  data() {
    return {
      qValue: '',
      method: 'https://redirect.it.etosoft.ru/https://api-fns.ru/api/ac',
      key: 'd3f25192550646cfda72995fa41c5c1113771835',
      page: 1,
      allItems: [],
      innContent: [],
      multibalanceKey: '3ca4cb8a9df8cc9956579a0e0bdbff1ef136b1e9',
      arraysCount: 1,
      queryIndex: 0,
      disableMultibalance: false,
      disableFns: false,
      file: null,
      daminaUrl: 'https://redirect.it.etosoft.ru/https://api.damia.ru/rs/balance'
    }
  },
  methods: {
    fileChanged(event) {
      this.file = event.target.files[0];
    },
    submitFile() {
      let selectedFile = this.file;
      if(!selectedFile) return
      this.disableMultibalance = true;
      if(selectedFile){
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = (event)=>{
         let data = event.target.result;
         let workbook = XLSX.read(data,{type:"binary"});
         workbook.SheetNames.forEach(sheet => {
              let worksheet = workbook.Sheets[sheet];
              const columnA = [];
              for (let z in worksheet) {
                if(z.toString()[0] === 'A'){
                  columnA.push(worksheet[z].v);
                }
              }
              // const slicedArray = this.split(columnA,1);
              this.arraysCount = columnA.length;
              const queries = columnA.map(item => `?key=${this.multibalanceKey}&inn=`+item)
              this.getInnItems(queries).then(() => {
                this.queryIndex = 0;
                this.arraysCount = 1;
                const formatedData = [
                  {
                    sheet: "ИНН",
                    content: this.innContent,
                    columns: [
                      {label: "ИНН", value: "inn"},
                      {label: "Год", value: "year"},
                      {label: "Строка", value: "row"},
                      {label: "Значение", value: "val"}
                    ]
                  }
                ]
                xlsx(formatedData, this.settings)
              }).finally(() => {
                this.disableMultibalance = false;
              })
         });
       }
      }
    },
    getInnItems(queries) {
      return this.axios.get(`${this.daminaUrl}${queries[this.queryIndex]}`).then(({data}) => {
        for (let inn in data) {
          data[inn]
          for (let year in data[inn]) {
            data[inn][year]
            for (let row in data[inn][year]) {
              this.innContent.push({
                inn: inn,
                year: year,
                row: row,
                val: data[inn][year][row]
              })
            }
          }
        }
        if(this.queryIndex < this.arraysCount-1) {
          this.queryIndex++;
          return this.getInnItems(queries)
        }
        return             
      })
    },
    submit() {
      this.disableFns = true;
      this.getItems().then(() => {
        const sheets = this.allItems.map(item => Object.keys(item)[0])
        const uniq = [...new Set(sheets)]
        const formatedData = uniq.map(item => {
          return {
            sheet: 1,
            content: this.allItems.map(sub_item => sub_item[item]),
            columns: Object.keys(this.allItems[0][item]).map(item => {
              return {label:item,value:item}
            })
          }
        })
        xlsx(formatedData, this.settings)
      }).finally(() => {
        this.disableFns = false;
      })
    },
    getItems() {
      return this.axios.get(`${this.method}?key=${this.key}&q=${this.qValue}&page=${this.page}`).then(({data}) => {
        if(!data.items) return
        this.allItems = this.allItems.concat(data.items);
        this.page += 1;
        if(data.Count && data.Count == 100) {
          return this.getItems()
        }
        else {
          this.page = 1;
          return
        }
      });
    },
    split(array, n) {
      let [...arr]  = array;
      var res = [];
      while (arr.length) {
        res.push(arr.splice(0, n));
      }
      return res;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
}
.main-form input {
  height: 24px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #424346;
  border-radius: 6px;
  font-size: 18px;
  padding: 6px;
}
.main-form button {
  /* height: 30px; */
  cursor: pointer;
  width: 100%;
  margin-top: 12px;
  border: 0;
  background-color: #009847;
  border-radius: 6px;
  font-size: 18px;
  padding: 8px;
  color: #FFF;
}
.main-form button:disabled {
  background-color: rgba(239, 239, 239, 0.8);
  color: #000;
  cursor: auto;
}
.main-form-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
